// src/features/clientSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const rejectPO = createAsyncThunk(
  "reject/rejectPO",
  async ({ id, reason }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/reject-pay-order/admin/appsell/${id}`,
        { reason },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.data) {
        return rejectWithValue("Failed to delete client");
      }

      return id; // Returning clientId to identify which client was deleted
    } catch (error) {
      const message =
        error.response?.data?.message ||
        "An error occurred during the deletion";
      return rejectWithValue(message);
    }
  }
);

const rejectPOSlice = createSlice({
  name: "reject",
  initialState: {
    status: null,
    error: null,
    loading: false,
  },
  reducers: {
    rejectPOStatus: (state) => {
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rejectPO.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(rejectPO.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
      })
      .addCase(rejectPO.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { rejectPOStatus } = rejectPOSlice.actions;
export default rejectPOSlice.reducer;
