import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchClientBrief = createAsyncThunk(
  "clientBrief/fetchClientBrief",
  async ({ page = 1, limit = 10, search , userId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(`/fetch-all-briefs-client/admin/${userId}`, {
        params: {
          limit,
          page,
          search,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch briefs");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching briefs"
      );
    }
  }
);

const fetchClientBriefSlice = createSlice({
  name: "clientBrief",
  initialState: {
    items: [],
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientBrief.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchClientBrief.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.briefs;
        state.totalItems = action.payload.total;
        state.loading = false;
      })
      .addCase(fetchClientBrief.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default fetchClientBriefSlice.reducer;
