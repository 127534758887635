// src/productsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

export const fetchAlterRequired = createAsyncThunk(
  "plan/AlterRequired",
  async ({ page=1, limit=10 , search}, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        "/fetch-briefs-alter-required-plans/admin-appsells",
        {
          params: { page, limit , search},
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch alter plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

// export const pendingPlanApi = createAsyncThunk(
//   "plan/AlterPlanPending",
//   async ({ page=1, limit=10, search }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("authToken");
//       const response = await axiosInstance.get(
//         "/fetch-briefs-pending-plans-list/admin-appsells",
//         {
//           params: { page, limit, search },
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.data || response.status !== 200) {
//         throw new Error("Failed to fetch plans");
//       }

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.message || "An error occurred while fetching plans"
//       );
//     }
//   }
// );

export const alterRequiredByBriefID = createAsyncThunk(
  "plan/alterRequiredByBriefID",
  async ({ brief_id, page=1, limit=10, search }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axiosInstance.get(
        `/fetch-plans-altered-required/admin-appsells/${brief_id}`,
        {
          params: { page, limit, search },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data || response.status !== 200) {
        throw new Error("Failed to fetch plans");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.message || "An error occurred while fetching plans"
      );
    }
  }
);

const modificationRequiredSlice = createSlice({
  name: "AlterRequired",
  initialState: {
    itemsA: [],
    alterPlanById: [],
    totalItems: 0,
    status: "idle",
    error: null,
    loading: false,
    pendingPlan: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlterRequired.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchAlterRequired.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.itemsA = action.payload.plans;
        state.totalItems = action.payload.totalPlans;
        state.loading = false;
      })
      .addCase(fetchAlterRequired.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(alterRequiredByBriefID.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(alterRequiredByBriefID.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.alterPlanById = action.payload;
        state.totalItems = action.payload.totalPlans;
        state.loading = false;
      })
      .addCase(alterRequiredByBriefID.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })

    //   .addCase(pendingPlanApi.pending, (state, action) => {
    //     state.status = "loading";
    //     state.loading = true;
    //   })
    //   .addCase(pendingPlanApi.fulfilled, (state, action) => {
    //     state.status = "succeeded";
    //     state.pendingPlan = action.payload;
    //     state.totalItems = action.payload.totalPlans;
    //     state.loading = false;
    //   })

    //   .addCase(pendingPlanApi.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //     state.loading = false;
    //   });
  },
});

export default modificationRequiredSlice .reducer;
