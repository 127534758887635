// singleBriefSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import axiosInstance from "../httpService/httpService";

export const fetchSingleBrief = createAsyncThunk(
  "singleBrief/fetchSingleBrief",
  async (id) => {
    const response = await axiosInstance.get(
      `/fetch-single-brief-admin/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    return response.data;
  }
);

const singleBriefSlice = createSlice({
  name: "singleBrief",
  initialState: {
    brief: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleBrief.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleBrief.fulfilled, (state, action) => {
        state.loading = false;
        state.brief = action.payload;
      })
      .addCase(fetchSingleBrief.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default singleBriefSlice.reducer;
