import React from "react";
import { Input, DatePicker, Select, Tooltip } from "antd";
import moment from "moment";

const { Option } = Select;

const handleVisibilityToggle = (column, setVisibilityStates) => {
  setVisibilityStates((prevState) => ({
    ...prevState,
    [column]: !prevState[column],
  }));
};

const getColumn = (
  title,
  dataIndex,
  visibilityStates,
  setVisibilityStates,
  renderInput
) => ({
  title: (
    <Tooltip
      title={visibilityStates[dataIndex] ? `Hide ${title}` : `Show ${title}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent:'center',
          // justifyContent:'center',
          gap: 4,
          cursor: "pointer",
          color: visibilityStates[dataIndex] ? "inherit" : "grey",
        }}
        onClick={() => handleVisibilityToggle(dataIndex, setVisibilityStates)}
      >
        <span>{title}</span>
        <img
          src={`/images/${
            visibilityStates[dataIndex] ? "visi" : "icons8-invisible-24"
          }.png`}
          width="17"
          alt="visibility toggle icon"
        />
      </div>
    </Tooltip>
  ),
  dataIndex,
  key: dataIndex,
  visible: visibilityStates[dataIndex],
  render: renderInput,
});

const restrictNonNumericInput = (event) => {
  if (
    event.key.length === 1 &&
    !/[0-9]/.test(event.key) &&
    !event.ctrlKey &&
    !event.metaKey
  ) {
    event.preventDefault();
  }
};

const disablePastDates = (current) => {
  return current && current < moment().startOf("day");
};

const InputColumns = (
  visibilityStates,
  handleInputChange,
  handleDateChange,
  handleDropdownChange,
  setVisibilityStates,
  billingVendorOptionsObjects,
  printingVendorOptionsObjects,
  lightningVendorOptionsObjects,
  permissionVendorOptionsObjects,
  fabricationVendorOptionsObjects,
  creativeVendorOptionsObjects,
  dynamicColumns = []
) => [
  getColumn("Site Code", "site_code", visibilityStates, setVisibilityStates),
  getColumn("Region", "region_name", visibilityStates, setVisibilityStates),
  getColumn("City", "city_name", visibilityStates, setVisibilityStates),
  
  getColumn(
    "Quantity",
    "quantity",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.quantity}
        onChange={(e) => handleInputChange(e.target.value, record, "quantity")}
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Availability Date",
    "availability_date",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <DatePicker
        onChange={(date, dateString) =>
          handleDateChange(date, dateString, record, "availability_date")
        }
        disabledDate={disablePastDates}
        format="YYYY-MM-DD"
      />
    )
  ),
  getColumn(
    "Execution Date",
    "execution_date",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <DatePicker
        onChange={(date, dateString) =>
          handleDateChange(date, dateString, record, "execution_date")
        }
        disabledDate={disablePastDates}
        format="YYYY-MM-DD"
      />
    )
  ),
  getColumn(
    "Start Date",
    "start_date",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <DatePicker
        onChange={(date, dateString) =>
          handleDateChange(date, dateString, record, "start_date")
        }
        disabledDate={disablePastDates}
        format="YYYY-MM-DD"
      />
    )
  ),
  getColumn(
    "End Date",
    "end_date",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <DatePicker
        onChange={(date, dateString) =>
          handleDateChange(date, dateString, record, "end_date")
        }
        disabledDate={disablePastDates}
        format="YYYY-MM-DD"
      />
    )
  ),
  getColumn(
    "Removal Date",
    "removal_date",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <DatePicker
        onChange={(date, dateString) =>
          handleDateChange(date, dateString, record, "removal_date")
        }
        disabledDate={disablePastDates}
      />
    )
  ),
  getColumn(
    "Duration",
    "duration",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.duration}
        onChange={(e) => handleInputChange(e.target.value, record, "duration")}
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Site Rental Cost",
    "site_rental_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.site_rental_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "site_rental_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Billing Vendor",
    "billing_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.billing_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "billing_vendor")
        }
      >
        {billingVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Site Vendor",
    "site_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.site_vendor}
        onChange={(value) => handleDropdownChange(value, record, "site_vendor")}
      >
        {billingVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Display Cost",
    "display_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.display_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "display_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Lightning Cost",
    "lightning_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.lightning_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "lightning_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Lightning Vendor",
    "lightning_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.lightning_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "lightning_vendor")
        }
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      >
        {lightningVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Fabrication Cost",
    "fabrication_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.fabrication_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "fabrication_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Fabrication Vendor",
    "fabrication_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.fabrication_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "fabrication_vendor")
        }
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      >
        {fabricationVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Creative Cost",
    "creative_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.creative_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "creative_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Tax Percentage",
    "tax_percentage",
    visibilityStates,
    setVisibilityStates,
    (_, record) => <Input disabled value="16%" readOnly />
  ),
  getColumn(
    "Other Tax",
    "other_tax",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.other_tax}
        onChange={(e) => handleInputChange(e.target.value, record, "other_tax")}
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Site Vendor Cost",
    "site_vendor_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.site_vendor_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "site_vendor_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Creative Vendor",
    "creative_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.creative_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "creative_vendor")
        }
      >
        {creativeVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Permission Cost",
    "permission_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.permission_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "permission_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Permission Vendor",
    "permission_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "100%" }}
        value={record.permission_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "permission_vendor")
        }
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      >
        {permissionVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Printing Cost",
    "printing_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.printing_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "printing_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Printing Vendor",
    "printing_vendor",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Select
        style={{ width: "170px" }}
        value={record.printing_vendor}
        onChange={(value) =>
          handleDropdownChange(value, record, "printing_vendor")
        }
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      >
        {printingVendorOptionsObjects.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    )
  ),
  getColumn(
    "Agency Commission",
    "agency_comission",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.agency_comission}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "agency_comission")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  getColumn(
    "Additional Cost",
    "additional_cost",
    visibilityStates,
    setVisibilityStates,
    (_, record) => (
      <Input
        value={record.additional_cost}
        onChange={(e) =>
          handleInputChange(e.target.value, record, "additional_cost")
        }
        onKeyDown={restrictNonNumericInput}
      />
    )
  ),
  ...dynamicColumns.map((column) =>
    getColumn(
      column.title,
      column.dataIndex,
      visibilityStates,
      setVisibilityStates,
      (_, record) => {
        if (column.type === "varchar") {
          return (
            <Input
              value={record[column.dataIndex]}
              onChange={(e) =>
                handleInputChange(e.target.value, record, column.dataIndex)
              }
            />
          );
        } else if (column.type === "integer") {
          return (
            <Input
              value={record[column.dataIndex]}
              onChange={(e) =>
                handleInputChange(e.target.value, record, column.dataIndex)
              }
              onKeyDown={restrictNonNumericInput}
            />
          );
        }

        return null;
      }
    )
  ),
];

export default InputColumns;
