import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../httpService/httpService";

// Fetch regions thunk
export const fetchRegions = createAsyncThunk(
  "regions/fetchRegions",
  async () => {
    const response = await axiosInstance.get("/appsells/fetch-regions");
    return response.data;
  }
);

// Fetch cities based on region thunk
export const fetchCitiesByRegion = createAsyncThunk(
  "regions/fetchCitiesByRegion",
  async (regionId) => {
    const response = await axiosInstance.get(
      `/fetch-all-cities-region-wise/${regionId}`
    );
    return response.data;
  }
);

// Fetch locations based on city thunk
export const fetchLocationsByCity = createAsyncThunk(
  "regions/fetchLocationsByCity",
  async (cityId) => {
    console.log("cityId", cityId);
    const response = await axiosInstance.post(
      `/fetch-all-locations-city-wise/`,
      { city_ids: cityId }
    );
    return response.data;
  }
);

// Fetch locations based on city thunk
export const fetchSitesByLocation = createAsyncThunk(
  "regions/fetchSitesByLocation",
  async (cityId) => {
    console.log("cityId", cityId);
    const response = await axiosInstance.post(`/admin/fetch-sites`, {
      location_ids: cityId,
    });
    return response.data;
  }
);

export const createPlan = createAsyncThunk(
  "regions/createPlan",
  async ({ plan_form, plan_id, hiddenColumns }) => {
    const token = localStorage.getItem("authToken");
    console.log("plan_id", plan_id);
    const response = await axiosInstance.post(
      `/create-plansites/admin-appsell/${plan_id}`,
      {
        hide_columns: hiddenColumns,
        plan_form: plan_form,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

const regionsSlice = createSlice({
  name: "regions",
  initialState: {
    regions: [],
    cities: [],
    areas: [],
    data: [],
    plan: [],
    loading: false,
    error: null,
  },
  reducers: {
    setAreas(state, action) {
      state.areas = action.payload;
    },
    resetSites(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.loading = false;
        state.regions = action.payload;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCitiesByRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCitiesByRegion.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
      })
      .addCase(fetchCitiesByRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchLocationsByCity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLocationsByCity.fulfilled, (state, action) => {
        state.loading = false;
        state.areas = action.payload;
      })
      .addCase(fetchLocationsByCity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSitesByLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSitesByLocation.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSitesByLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.plan = action.payload;
      })
      .addCase(createPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setAreas, resetSites } = regionsSlice.actions;

export default regionsSlice.reducer;
