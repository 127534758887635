// import React from "react";
// import { useParams, useLocation } from "react-router-dom";
// import InputTable from "./InputTable";
// import Sidebar from "../Admin/Sidebar";
// import { Row, Col } from "antd";

// function SiteTablePage() {
//   const { plan_id } = useParams();
//   const { state } = useLocation();
//   const { selectedRows, uploadedFile } = state;

//   console.log(
//     "plan id in params is : " +
//       plan_id +
//       " Selected rows : " +
//       selectedRows.length
//   );

//   //   return (
//   //     <div style={{ display: "flex" }}>
//   //       <div className="main-content" style={{ width: "100%" }}>
//   //         {selectedRows.length > 0 && uploadedFile === null ? (
//   //           <div style={{ padding: "10px" }}>
//   //             {selectedRows && <InputTable CCC={selectedRows} />}
//   //           </div>
//   //         ) : (
//   //           <p
//   //             style={{
//   //               display: "flex",
//   //               justifyContent: "center",
//   //               alignItems: "center",
//   //               height: "70vh",
//   //               fontFamily: "gothamBook",
//   //             }}
//   //           >
//   //             No Selected Sites
//   //           </p>
//   //         )}
//   //       </div>
//   //     </div>
//   //   );
//   return (
//     <>
//       <div>
//         <Row>
//           <Col>
//             <h1
//               style={{
//                 margin: "0px",
//                 padding: "0px",
//                 color: "#294799",
//                 fontSize: "20px",
//                 fontFamily: "gothamBook",
//                 paddingLeft: "15px",
//                 marginTop: "45px",
//               }}
//             >
//               Add Sites
//             </h1>
//           </Col>
//         </Row>
//         <hr
//           style={{
//             padding: "0px",
//             margin: "0px",
//             border: "none",
//             borderTop: "1px solid rgba(0, 0, 0, 0.12)",
//             marginTop: "10px",
//             boxShadow: "none",
//             width: "100%",
//           }}
//         />
//       </div>

//       {selectedRows.length > 0 && uploadedFile === null ? (
//         <div style={{ padding: "10px", overflow: "auto hidden" }}>
//           {selectedRows && <InputTable CCC={selectedRows} />}
//         </div>
//       ) : (
//         <p
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "70vh",
//             fontFamily: "gothamBook",
//           }}
//         >
//           No Selected Sites
//         </p>
//       )}
//     </>
//   );
// }

// export default SiteTablePage;

import React from "react";
import { useParams, useLocation } from "react-router-dom";
import InputTable from "./InputTable";
import Sidebar from "../Admin/Sidebar";
import { Row, Col } from "antd";

function SiteTablePage() {
  const { plan_id } = useParams();
  
  const { state } = useLocation();
  const { selectedRows, uploadedFile } = state ;
  
  console.log(
    "Plan ID in params is: " +
      plan_id +
      " Selected rows: " +
      selectedRows.length
  );
  console.log("selected rows : " + JSON.stringify(selectedRows)) ;
  return (
    <div style={{ display: "flex" }}>
      <div className="sidebar" style={{ width: "283px" }}>
        <Sidebar />
        <div className="main-content" style={{ width: "390%" }}>
          <div>
            <Row>
              <Col>
                <h1
                  style={{
                    margin: "0px",
                    padding: "0px",
                    color: "#294799",
                    fontSize: "20px",
                    fontFamily: "gothamBook",
                    paddingLeft: "15px",
                    marginTop: "45px",
                  }}
                >
                  Add Plan Details
                </h1>
              </Col>
            </Row>
            <hr
              style={{
                padding: "0px",
                margin: "0px",
                border: "none",
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                marginTop: "10px",
                boxShadow: "none",
                width: "100%",
                marginBottom: "20px",
              }}
            />
          </div>
          <div></div>
          {selectedRows?.length > 0  ? (
            <div style={{ padding: "10px" }}>
              {selectedRows && <InputTable CCC={selectedRows} />}
            </div>
          ) : (
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                fontFamily: "gothamBook",
              }}
            >
              No Selected Sites
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SiteTablePage;
