import React, { useState, useEffect } from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Select,
  Input,
  DatePicker,
  Checkbox,
  Switch,
  message,
  Spin,
  Modal,
  Form,
} from "antd";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { parse, format, isValid } from "date-fns";
import { createPlan } from "../../features/AddSite/fetchRegionsSlice";

import { resetSites } from "../../features/AddSite/fetchRegionsSlice";
import useNavigationPrompt from "use-navigation-prompt";
import { useNavigationType } from "react-router-dom";

import InputColumns from "./InputColumns";

const { Option } = Select;

const billingVendorOptions = [
  "Media 99",
  "Hafiz Bro",
  "Sahar ad",
  "MNK",
  "Art vision",
  "AA",
  "One ten",
  "Out reach",
  "Hub",
  "Lahore ad",
  "Ravi",
  "Topaz",
  "Sherazi",
  "Flexo Sign",
  "Orange Ad",
  "Al Nasir",
  "Top Sign",
  "Signways",
  "Painter Babo",
  "Brand recall",
  "Media touch",
  "NMS",
  "Value ad",
  "Super Sign",
  "AW Sons",
  "Pindi Ad",
  "Classic ad",
  "Sign Pakistan",
  "The Sign",
  "Dimmension",
  "Mikab ad",
  "Ad Net",
  "Omlete",
  "Saad Communication",
  "Universal Ad",
  "Maaz",
  "Chinab ad",
  "Ad Care",
  "Siddique sign",
  "Younas Ad",
];
const billingVendorOptionsObjects = billingVendorOptions.map((option) => ({
  value: option,
  label: option,
}));

const printingVendorOptions = [
  "Adsells",
  "Ad innovation",
  "Chinab Ad",
  "Classic Ad",
  "Sign System",
  "SLS",
  "Digirex",
  "Print Arena",
  "Ad saquad",
];
const dataTypes = ["varchar", "integer"];
const dataTypeObjects = dataTypes.map((option) => ({
  value: option,
  label: option,
}));
const printingVendorOptionsObjects = printingVendorOptions.map((option) => ({
  value: option,
  label: option,
}));

const lightningVendorOptions = ["Ad glow", "Sign Scope"];

const lightningVendorOptionsObjects = lightningVendorOptions.map((option) => ({
  value: option,
  label: option,
}));

const permissionVendorOptions = ["PHA", "Cantt", "DHA"];

const permissionVendorOptionsObjects = permissionVendorOptions.map(
  (option) => ({ value: option, label: option })
);

const fabricationVendorOptions = ["Adsells", "Ad glow"];

const fabricationVendorOptionsObjects = fabricationVendorOptions.map(
  (option) => ({ value: option, label: option })
);

const creativeVendor = ["Adsells", "Ad glow"];
const creativeVendorOptionsObjects = creativeVendor.map((option) => ({
  value: option,
  label: option,
}));

const InputTable = ({ CCC, addPlan }) => {
  console.log("CCC length" + CCC.length);
  console.log("CCC has : " + JSON.stringify(CCC));
  const dispatch = useDispatch();
  const { plan_id, id } = useParams();
  console.log("id in param is  : " + plan_id);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const [data, setData] = useState(CCC);
  const [newData, setNewData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isHeaderSwitchChecked, setIsHeaderSwitchChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [columnname, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("");
  const [customColumns, setCustomColumns] = useState([]);
  const [visibilityStates, setVisibilityStates] = useState({
    site_code: true,
    region_name: true,
    city_name: true,
    quantity: true,
    availability_date: true,
    execution_date: true,
    start_date: true,
    end_date: true,
    removal_date: true,
    duration: true,
    site_rental_cost: true,
    billing_vendor: true,
    site_vendor: true,
    display_cost: true,
    lightning_cost: true,
    lightning_vendor: true,
    fabrication_cost: true,
    fabrication_vendor: true,
    creative_cost: true,
    creative_vendor: true,
    permission_cost: true,
    permission_vendor: true,
    printing_cost: true,
    printing_vendor: true,
    agency_comission: true,
    additional_cost: true,
    site_vendor_cost: true,
    other_tax: true,
    tax_percentage: true,
  });
  const [locationKeys, setLocationKeys] = useState([]);
  const [isNavigationBlocked, setIsNavigationBlocked] = useState(false);
  useNavigationPrompt(isNavigationBlocked, "Your changes are unsaved...");
 
  useEffect(() => {
    setData(CCC);
    validateForm(CCC); // Initial validation
  }, [CCC]);

  const handleDropdownChange = (value, key, column) => {
    const newData = data.map((item) => {
      if (item.site_id === key.site_id) {
        return { ...item, [column]: value };
      }
      return item;
    });
    setData(newData);
    setNewData(newData);
    validateForm(newData);
  };

  useEffect(() => {
    setIsNavigationBlocked(true);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    window.addEventListener("popstate", () => {
      console.log("User clicked back button");
    });
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", () => {
        console.log("User clicked back button");
        // handleBeforeUnload() ;
      });
    };
  }, [data]);
  useEffect(() => {
    // Update location keys based on navigation type
    if (navigationType === "PUSH") {
      setLocationKeys((prevKeys) => [location.key, ...prevKeys]);
    }

    if (navigationType === "POP") {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([, ...remainingKeys]) => remainingKeys);

        // Handle forward navigation event
        console.log("Forward navigation");
      } else {
        setLocationKeys((prevKeys) => [location.key, ...prevKeys]);

        // Handle back navigation event
        console.log("Back navigation");
      }
    }
  }, [location,data]);
  const handleInputChange = (value, key, column) => {
    const newData = data.map((item) => {
      if (item.site_id === key.site_id) {
        return { ...item, [column]: value };
      }
      return item;
    });
    console.log("new data is  : " + JSON.stringify(newData));
    setData(newData);
    setIsNavigationBlocked(true);
    setNewData(newData);
    validateForm(newData);
  };

  const handleDateChange = (date, dateString, key, column) => {
    const newData = data.map((item) => {
      if (item.site_id === key.site_id) {
        return { ...item, [column]: dateString };
      }
      return item;
    });
    console.log("new datawith date  is  : " + JSON.stringify(newData));
    setData(newData);
    setNewData(newData);
    validateForm(newData);
  };

  const handleVisibilityToggle = (column) => {
    setVisibilityStates((prevState) => ({
      ...prevState,
      [column]: !prevState[column],
    }));
  };

  // const validateForm = (data) => {
  //   console.log("inside validate");
  //   console.log("data is  : " + JSON.stringify(data));

  //   const allColumns = [
  //     "quantity",
  //     "availability_date",
  //     "execution_date",
  //     "start_date",
  //     "end_date",
  //     "removal_date",
  //     "duration",
  //     "site_rental_cost",
  //     "billing_vendor",
  //     "site_vendor",
  //     "display_cost",
  //     "lightning_cost",
  //     "lightning_vendor",
  //     "fabrication_cost",
  //     "fabrication_vendor",
  //     "creative_cost",
  //     "creative_vendor",
  //     "permission_cost",
  //     "permission_vendor",
  //     "printing_cost",
  //     "printing_vendor",
  //     "agency_comission",
  //     "additional_cost",
  //     "site_vendor_cost",
  //     "other_tax",
  //     "extra_columns",
  //   ];

  //   console.log("allColumns: " + allColumns);

  //   const isComplete = data.every((item) => {
  //     return allColumns.every((column) => {
  //       let isFilled = true;

  //       if (column.includes(".")) {
  //         const nestedColumns = column.split(".");
  //         let nestedValue = item;
  //         for (const key of nestedColumns) {
  //           if (nestedValue[key]) {
  //             nestedValue = nestedValue[key];
  //           } else {
  //             isFilled = false;
  //             break;
  //           }
  //         }
  //       } else {
  //         console.log("item[column] = " + item[column]) ;
  //         isFilled = !!item[column];
  //       }

  //       if (!isFilled) {
  //         console.log(
  //           `Missing value in column '${column}' for site_id: ${item.site_id}`
  //         );
  //       }
  //       return isFilled;
  //     });
  //   });

  //   console.log("Form completion status: ", isComplete);
  //   setIsButtonDisabled(!isComplete);
  //   return isComplete;
  // };
  const validateForm = (data) => {
    console.log("inside validate");
    console.log("data is  : " + JSON.stringify(data));

    const allColumns = [
      "quantity",
      "availability_date",
      "execution_date",
      "start_date",
      "end_date",
      "removal_date",
      "duration",
      "site_rental_cost",
      "billing_vendor",
      "site_vendor",
      "display_cost",
      "lightning_cost",
      "lightning_vendor",
      "fabrication_cost",
      "fabrication_vendor",
      "creative_cost",
      "creative_vendor",
      "permission_cost",
      "permission_vendor",
      "printing_cost",
      "printing_vendor",
      "agency_comission",
      "additional_cost",
      "site_vendor_cost",
      "other_tax",
      "extra_columns",
    ];

    console.log("allColumns: " + allColumns);

    const isComplete = data.every((item) => {
      return allColumns.every((column) => {
        let isFilled = true;

        if (column.includes(".")) {
          const nestedColumns = column.split(".");
          let nestedValue = item;
          for (const key of nestedColumns) {
            if (nestedValue && nestedValue.hasOwnProperty(key)) {
              nestedValue = nestedValue[key];
            } else {
              isFilled = false;
              break;
            }
          }
        } else {
          const value = item[column];
          // Check if the value is not undefined or null
          isFilled = value !== undefined && value !== null;
        }

        if (!isFilled) {
          console.log(
            `Missing value in column '${column}' for site_id: ${item.site_id}`
          );
        }
        return isFilled;
      });
    });

    console.log("Form completion status: ", isComplete);
    setIsButtonDisabled(!isComplete);
    return isComplete;
  };

  useEffect(() => {
    console.log("Button is disabled:  " + isButtonDisabled);
  }, [isButtonDisabled]);
  const hiddenColumns = Object.keys(visibilityStates).filter(
    (key) => !visibilityStates[key]
  );

  console.log("Hidden columns:", hiddenColumns);
  const handleButtonClick = async (customColumns) => {
    console.log("button clicked");

    setIsLoading(true);

    const filteredData = data.map((item) => {
      // Extract common fields
      const commonFields = {
        site_id: item.site_id,
        quantity: item.quantity,
        availability_date: item.availability_date,
        execution_date: item.execution_date,
        start_date: item.start_date,
        end_date: item.end_date,
        removal_date: item.removal_date,
        duration: item.duration,
        site_rental_cost: Number(item.site_rental_cost),
        billing_vendor: item.billing_vendor,
        site_vendor: item.site_vendor,
        display_cost: Number(item.display_cost) || 0,
        lightning_cost: Number(item.lightning_cost),
        lightning_vendor: item.lightning_vendor,
        fabrication_cost: Number(item.fabrication_cost),
        fabrication_vendor: item.fabrication_vendor,
        creative_cost: Number(item.creative_cost),
        creative_vendor: item.creative_vendor,
        permission_cost: Number(item.permission_cost),
        permission_vendor: item.permission_vendor,
        printing_cost: Number(item.printing_cost),
        printing_vendor: item.printing_vendor,
        agency_comission: Number(item.agency_comission),
        additional_cost: Number(item.additional_cost),
        tax_percentage: 16,
        site_vendor_cost: Number(item.site_vendor_cost),
        other_tax: Number(item.other_tax),
      };

      // Create extra_columns based on customColumns
      const extraColumns = customColumns.map((col) => {
        let col_val;
        if (col.type === "integer") {
          col_val = Number(item[col.dataIndex]);
        } else {
          col_val = item[col.dataIndex];
        }
        return {
          col_name: col.dataIndex,
          col_type: col.type,
          col_value: col_val,
        };
      });

      // Log the details of each column
      extraColumns.forEach((column) => {
        console.log(`Column Name: ${column.col_name}`);
        // console.log(`Column Type: ${column.type}`);

        console.log(`Column Type: ${column.col_type}`);
        console.log(`Column Value: ${column.col_value}`);
        console.log("---"); // Separator for readability
      });
      return {
        ...commonFields,
        extra_columns: extraColumns,
      };
    });

    console.log("filtered data is : " + JSON.stringify(filteredData));
    const isComplete = validateForm(filteredData);

    console.log("plan id is : " + plan_id);
    if (isComplete) {
      try {
        console.log("hidden columns are : " + hiddenColumns);
        console.log("filtered data is  : " + JSON.stringify(filteredData));
        console.log("plan id is : " + plan_id);
        const result = await dispatch(
          createPlan({
            plan_form: filteredData,
            plan_id: plan_id,
            hiddenColumns,
          })
        );
        console.log("result " + JSON.stringify(result));
        console.log("request status : " + result.meta.requestStatus);
        if (result.meta.requestStatus == "fulfilled") {
          message.success("Plan created successfully!");
          console.log("response is  : " + JSON.stringify(result.data));
          setData([]);
          dispatch(resetSites());
          navigate(-1);
          // setTimeout(() => {
          //   // navigate("/brief");

          // }, 2000);
        } else {
          throw new Error("Plan creation failed");
        }
      } catch (error) {
        console.log(error);
        message.error("Failed to create plan. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please fill all the fields before submitting.");
      // navigate(-1) ;
      setIsButtonDisabled(true);
      setIsLoading(false);
    }
  };

  const handleAddColumn = (newColumn) => {
    setAddModalVisible(true);
    console.log("Add column called...");
  };
  const handleAddcustom = (newColumn) => {
    setCustomColumns((prevColumns) => [...prevColumns, newColumn]);

    setVisibilityStates((prevState) => ({
      ...prevState,
      [newColumn.dataIndex]: true, // Setting the default visibility to true
    }));
  };
  const handleClose = () => {
    setAddModalVisible(false);
  };

  return (
    <div className="Input-Table-Container">
      <Table
        className="Input-table-After-Site"
        id="input-table"
        style={{ marginLeft: "5px" }}
        dataSource={data}
        columns={InputColumns(
          visibilityStates,
          handleInputChange,
          handleDateChange,
          handleDropdownChange,
          setVisibilityStates,
          billingVendorOptionsObjects,
          printingVendorOptionsObjects,
          lightningVendorOptionsObjects,
          permissionVendorOptionsObjects,
          fabricationVendorOptionsObjects,
          creativeVendorOptionsObjects,
          customColumns
        )}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: "max-content" }}
      />
      <Row>
        {/* marginTop:'-18%' */}
        <Col span={24} style={{ textAlign: "right", paddingTop: "20px" }}>
          {CCC.length > 0 && (
            <>
              <Button
                style={{
                  background: "#294799",
                  color: "white",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                  marginRight: "1%",
                }}
                onClick={() => handleAddColumn()}
                // disabled={isButtonDisabled || isLoading}
              >
                {isLoading ? <Spin /> : "Add Column"}
              </Button>
              <Button
                style={{
                  background: "#294799",
                  color: "white",
                  paddingRight: "20px",
                  paddingLeft: "20px",
                }}
                onClick={() => handleButtonClick(customColumns)}
                // disabled={isButtonDisabled || isLoading}
              >
                {isLoading ? <Spin /> : addPlan ? "Add" : "Create Plan"}
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Modal
        open={addModalVisible}
        onCancel={handleClose}
        // destroyOnClose()
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "50%",
            margin: "auto",
          }}
        >
          <h1
            style={{
              margin: "0px 0px 30px 0px",
              padding: "0px",
              color: "#294799",
              fontSize: "30px",
              fontFamily: "gothamBook",
            }}
          >
            Add Column
          </h1>
          <Form
            form={form}
            name="add_column"
            // onFinish={onFinish}
            onFinish={(values) => {
              const newColumn = {
                title: values.columnName,
                dataIndex: values.columnName.toLowerCase().replace(/\s+/g, "_"),
                key: values.columnName.toLowerCase().replace(/\s+/g, "_"),
                type: values.columnType,
              };

              handleAddcustom(newColumn); // Add new column to the table
              form.resetFields();
              handleClose(); // Close the modal after adding the column
            }}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item
              name="columnName"
              label={
                <span style={{ color: "#3E3E3E", fontFamily: "gothamMedium" }}>
                  Column Name
                </span>
              }
              rules={[{ required: true, message: "Please add a name!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="columnType"
              label={
                <span style={{ color: "#3E3E3E", fontFamily: "gothamMedium" }}>
                  Data Type
                </span>
              }
              rules={[
                { required: true, message: "Please select a data type!" },
              ]}
              style={{ flex: 1, marginRight: "8px" }}
            >
              <Select
                style={{
                  borderRadius: "8px",
                  borderColor: "#294799",
                  color: "#294799",
                  fontWeight: "500",
                  fontFamily: "gothamMedium",
                }}
                placeholder="Select Data Type"
              >
                {dataTypes.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "16px",
              }}
            >
              <Form.Item
                style={{
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#294799",
                    color: "white",
                    marginTop: "10px",
                  }}
                  htmlType="submit"
                >
                  Add
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default InputTable;
